
import { Vue, Component, Prop } from 'vue-property-decorator';
import Pagination from '@/components/board/pagination.vue';
import { getBookmarkBoardList } from '@/api/board';
import { getBusinessApplyList } from '@/api/businessApply';
// import ApplyDetail from './ApplyDetail.vue';
import ApplyDelete from './ApplyDelete.vue';

@Component({
  name: 'MypageApply',
  components: {
    Pagination,
    ApplyDelete,
  },
})
export default class extends Vue {
  mounted() {
    this.getBusinessApplyList();
    this.getMenuUid();
  }

  private boardContent = false;

  private isActive = true;

  private isActive2 = false;

  private applyTab() {
    this.applyTabContent = 0;
    this.isActive = true;
    this.isActive2 = false;
  }

  private applyTab2() {
    this.applyTabContent = 1;
    this.isActive = false;
    this.isActive2 = true;
  }

  private applyTabContent = 0;

  private listQuery = {
    page: 0,
    size: 10,
    searchType: 'title',
    searchValue: '',
  };

  private totalElements = 0;

  private totalPages = 0;

  private applies: any = [];

  private menuUid = '';

  private selectedApply: any = null;

  private detailVisible = false;

  private getBusinessApplyList() {
    getBusinessApplyList(this.listQuery).then((res) => {
      this.applies = res.data.content;
      this.totalElements = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getBusinessApplyList();
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getBusinessApplyList();
  }

  /* eslint-disable */
  private getMenuUid() {
    getBookmarkBoardList().then((res) => {
      if (res.data.length > 0) this.menuUid = res.data[0].menuList[0];
    });
  }
  /* eslint-enable */

  private handleDetail(apply: any) {
    if (apply) {
      this.selectedApply = {
        idx: apply.idx,
        businessName: apply.businessName,
        businessIdx: apply.businessIdx,
        applyStatus: apply.applyStatus,
      };
      // console.log(apply.idx);
    } else {
      this.selectedApply = null;
    }
    this.detailVisible = !this.detailVisible;
  }
}
